import './StockDetails.scss';
import apiService from '../../../services/apiService'
import { useState, useEffect } from 'react';


function StockDetails({ticker}){
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    // Dates data
    useEffect(()=>{
        async function fetchData(){
            setLoading(true)
            const data = await apiService.getStockFundamentals(ticker)
            setData(data)
            setLoading(false)
        }
        fetchData();
    },[ticker])

    return (loading ? <></> : 
        <>
            <div className="detailsCol">
                <div className="technicalLabels">
                    <ul>
                        <li>Open</li>
                        <li>High</li>
                        <li>Low</li>
                        <li>Volume</li>
                        <li>Average Volume</li>
                    </ul>
                </div>
                <div className="technicalValues">
                    <ul>
                        <li>{data["open"]}</li>
                        <li>{data["high"]}</li>
                        <li>{data["low"]}</li>
                        <li>{data["volume"]}</li>
                        <li>{data["avg_volume"]}</li>
                    </ul>
                </div>
            </div>

            <div className="detailsCol">
                <div className="technicalLabels">
                    <ul>
                        <li>52 Week High</li>
                        <li>52 Week Low</li>
                        <li>Market Cap</li>
                        <li>PE Ratio</li>
                        <li>EPS</li>
                    </ul>
                </div>
                <div className="technicalValues">
                    <ul>
                        <li>{data["52_wk_high"]}</li>
                        <li>{data["52_wk_low"]}</li>
                        <li>{data["mkt_cap"]}</li>
                        <li>{data["pe_ratio"]}</li>
                        <li>{data["eps"]}</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default StockDetails;