import React from 'react';
import './assets/css/index.scss'
import { render } from 'react-dom';
import App from './components/App';
import { Popover, FocusStyleManager } from '@blueprintjs/core';
import { Auth0Provider } from "@auth0/auth0-react";
//Unused: import apiService from './services/apiService'

// Set Blueprint.JS defaults
FocusStyleManager.onlyShowFocusOnTabs();
Popover.defaultProps.modifiers = { computeStyle: { gpuAcceleration: false } };

const Root = () => {
  return <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    useRefreshTokens="true"
    redirectUri={window.location.origin}
  >
    <App />
  </Auth0Provider>;
}

render(<Root />, document.getElementById('root'));
