import './TopMessageBar.scss';
import {Navbar} from '@blueprintjs/core';

function TopMessageBar(){
    return(
        <Navbar className="topbar-body bp3-dark">
            <p>Please Note: Get in the Money (GITM) is currently under development. All the content on this website is subject to change.</p>
        </Navbar>

    )
}

export default TopMessageBar;