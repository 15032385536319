//import { Button, Navbar, Alignment , Popover, Position , Icon} from '@blueprintjs/core';
//import logo from '../../assets/img/logo-nopadding.png';
import './OptionChain.scss';
import { Cell, Column, Table } from "@blueprintjs/table";
import { ResizeSensor } from '@blueprintjs/core';
import { useState, useEffect, useContext} from 'react';
import apiService from '../../../services/apiService'
import  AppContext  from '../AppContext';


function getRandomNumber(max) {
    return (Math.random() * max).toFixed(2);
  }

function OptionChain({ticker}){
    const {date, sp} = useContext(AppContext);

    //console.log(filterValues);
    const [data, setData] = useState(new Array(50).fill(undefined).map((val, idx) => 
        ({
            "strike_price": getRandomNumber(300),
            "last_price" : getRandomNumber(10),
            "ask_price" : getRandomNumber(10),
            "bid_price" : getRandomNumber(10),
            "chng_price" : getRandomNumber(2),
            "expiry_date" : null
        })));
    const [loading, setLoading] = useState(true);
    const [colWidth, setColWidth] = useState(150);

    useEffect(()=>{
        async function fetchData(){
            setLoading(true)
            const data = await apiService.getCallOptionHistoryFor(ticker, date, sp)
            setData(data)
            setLoading(false)
        }
        if (date && sp!==""){
            fetchData();
        }
    },[ticker, date, sp])

    const strike_price = (rowIndex) => {
        return <Cell loading={loading}>{data[rowIndex].strike_price}</Cell>
    };
    const last_price = (rowIndex) => {
        return <Cell loading={loading}>{data[rowIndex].last_price}</Cell>
    };
    const bid_price = (rowIndex) => {
        return <Cell loading={loading}>{data[rowIndex].bid_price}</Cell>
    };
    const ask_price = (rowIndex) => {
        return <Cell loading={loading}>{data[rowIndex].ask_price}</Cell>
    };
    const chng_price = (rowIndex) => {
        return <Cell loading={loading}>{data[rowIndex].chng_price}</Cell>
    };
    const expiry_date = (rowIndex) => {
        return <Cell loading={loading}>{data[rowIndex].expiry_date}</Cell>
    };

    const colWidthArr = new Array(6).fill(undefined).map((val, idx) => (colWidth));

    return(    loading ? <></> : 
        <ResizeSensor onResize={(entries) =>{
            setColWidth(Math.max(((entries[0].contentRect.width-30)/6), 100))
        }}>  
            <Table className="bp3-dark table-full-width" numRows={data.length} columnWidths={colWidthArr}>
                <Column name="Strike Price" cellRenderer={strike_price}/>
                <Column name="Last Price" cellRenderer={last_price}/>
                <Column name="Ask Price" cellRenderer={ask_price}/>
                <Column name="Bid Price" cellRenderer={bid_price}/>
                <Column name="Change in Price" cellRenderer={chng_price}/>
                <Column name="Expiry Date" cellRenderer={expiry_date}/>
            </Table>
        </ResizeSensor>    
    )
}

export default OptionChain;