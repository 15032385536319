import './AccountMenu.scss'
import { Menu, MenuItem} from '@blueprintjs/core';
import {useHistory} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useContext} from 'react';
import  AppContext  from '../AppContext';

function AccountMenu(){
    const {setActivePage} = useContext(AppContext);
    const { logout } = useAuth0();
    const history = useHistory();
    const handleSettingsClick = () => {
        setActivePage("Account");
        history.push('/account');
    }
    return (
        <Menu>
            <MenuItem text="Settings" onClick={handleSettingsClick}/>
            <MenuItem text="Logout" onClick={() => logout({ returnTo: window.location.origin })}/>
        </Menu>
    );
}

export default AccountMenu;