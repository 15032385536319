import './App.scss';
import LoggedInScreen from './LoggedInScreen'
import { useAuth0 } from "@auth0/auth0-react";

function App() {

  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated, isLoading } = useAuth0();

  // Auth0 needs time to collect token received
  if (isLoading) {
    return <></>;
  }

  return ( isAuthenticated ? <LoggedInScreen/> : loginWithRedirect());
}

export default App;
