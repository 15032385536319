import { ButtonGroup, Button, Intent } from '@blueprintjs/core';


function OptionSPFilter({loading, data, selectedSP, onChangeHandler}){
    const SPBtn = (({data})=>(
        <>
            {data.map(sp =>(
                <Button key={sp}
                        intent={sp === selectedSP ? Intent.PRIMARY : Intent.NONE}
                        text={sp}
                        onClick={onChangeHandler}></Button>
                        )
                    )
            }
        </>
    ))

    return ( data.length === 0 ? 
                <></>
            :
                <div className="col">
                    <label className="btnLabel">Strike Price</label>
                    <ButtonGroup className="btnGroupDiv" minimal>
                        <SPBtn data={data}></SPBtn>
                    </ButtonGroup>
                </div>
    )

}

export default OptionSPFilter;