import './OptionChart.scss';
import { useState, useEffect, useContext, useRef} from 'react';
import apiService from '../../../services/apiService'
import  AppContext  from '../AppContext';
import uPlot from "uplot";
import { Colors } from "@blueprintjs/core";

function OptionChart({ticker}){
    const {date, sp} = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null)
    const [xy, setXY] = useState(null)

    const plotRef = useRef(null);
    const chartDivRef = useRef(null);
    const dataCanvasRef = useRef(null);

    useEffect(()=>{
        setLoading(true)
        async function fetchData(){
            const data = await apiService.getCallOptionHistoryFor(ticker, date, sp)
            return data
        }
        // Only get data once both filters are set
        if (date && sp!==""){
            fetchData().then(function (rawData){
                let x = rawData.map(obj => {
                    let parsedDate = new Date(obj.curr_time)
                    return parsedDate.valueOf()/1000
                })
                let y = rawData.map(obj => parseFloat(obj.last_price))
                const data = [x,y]
                setData(data)
                setLoading(false)
            })
        } else {
            return;
        }
    },[ticker, date, sp])

    useEffect(()=>{

        let opts = {
            width: 600,
            height: 500,
            class: "dashboardChart",
            scales: {
                x: {
                    time: true,
                },
            },
            series: [
              {},
              {
                label: "Price",
                stroke: Colors.GREEN4,
                width: 5,
                points:{
                    show:false,
                }
              }
            ],
            axes: [
                {
                    stroke: Colors.WHITE,
                    grid: {
                        show: false 
                    },
                    ticks:{
                        show: false
                    }
                },
                {
                    show:false,
                    stroke: Colors.WHITE,
                    grid: {
                        show: false 
                    },
                    ticks:{
                        show: false
                    }
                }
            ],
            legend: 
            {
                show: false
            },
            cursor: {
                points:{
                    show: false
                }
            },
            hooks: {
                // Hook to draw trendline
                drawSeries: [
                    (u, idx) => {
                        let ctx = u.ctx;

                        let s  = u.series[idx];
                        let xd = u.data[0];
                        let yd = u.data[idx];

                        let [i0, i1] = s.idxs;

                        let x0 = u.valToPos(xd[i0], 'x', true);
                        let y0 = u.valToPos(yd[i0], 'y', true);
                        let x1 = u.valToPos(xd[i1], 'x', true);
                        let y1 = u.valToPos(yd[i1], 'y', true);

                        const offset = (s.width % 2) / 2;

                        ctx.translate(offset, offset);

                        ctx.lineWidth = 3;
                        ctx.strokeStyle = "rgba(255, 255, 255, 0.2)"
                        ctx.beginPath();
                        ctx.setLineDash([5, 15]);
                        ctx.moveTo(x0, y0);
                        ctx.lineTo(x1, y1);
                        ctx.stroke();

                        ctx.translate(-offset, -offset);
                    }
                ],

                // Hook to set chart state for x/y values
                setCursor: [
                    (u) =>{
                        // TODO- Needs to be real Y not cursor Y :)
                        let xVal = parseInt(u.posToVal(u.cursor.left, 'x'));
                        let yVal = u.posToVal(u.cursor.top, 'y');
                        setXY([xVal, yVal])
                    }
                ]
            },
          };

        plotRef.current = new uPlot(opts, data, chartDivRef.current)

        return function cleanup(){
            plotRef.current.destroy();
        }
    }, [data])

    
    useEffect(()=>{
        // Only process after xy data is set
        if (!xy){
            return
        }

        // Data points
        let price = parseFloat(xy[1]).toFixed(2)
        let date = new Date(xy[0]*1000).toLocaleString()

        // Set ctx styles
        let ctx = dataCanvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, dataCanvasRef.current.width, dataCanvasRef.current.height);
        ctx.font = '40px "Helvetica Neue"';
        ctx.fillStyle = Colors.WHITE;
        ctx.fillText("$" + price, 10, (dataCanvasRef.current.height/2-20));
        ctx.font = '15px "Helvetica Neue"';
        ctx.fillText(date, 10, (dataCanvasRef.current.height/2+20));
    },[xy])

    return ( 
            <div className={loading ? "chartLoading" : "chartLoaded"} ref={chartDivRef}> 
                <canvas className="chartLegend" width="200" height="400" ref={dataCanvasRef}></canvas>
            </div>
    )
}

export default OptionChart;