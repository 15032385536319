import './Explore.scss';
import Subheader from '../features/Layout/Subheader';
import TopOptionCard from '../features/Explore/TopOptionCard'
import { Card, Elevation } from "@blueprintjs/core";

function Explore(){


    return ( 
        <>
            <Subheader></Subheader>
            <div className="exploreContainer">
                <Card className="exploreRow" elevation={Elevation.ONE}>
                    <div className="exploreHeader">
                        <h2>Top Options by Open Interest</h2>
                    </div>
                    <div className="exploreCarousel">
                        <TopOptionCard/>
                        <TopOptionCard/>
                        <TopOptionCard/>
                    </div>
                </Card>
                <Card className="exploreRow" elevation={Elevation.ONE}>
                    <div className="exploreHeader">
                        <h2>Top Options by Open Interest</h2>
                    </div>
                    <div className="exploreCarousel">
                        <TopOptionCard/>
                        <TopOptionCard/>
                        <TopOptionCard/>
                    </div>
                </Card>
                <Card className="exploreRow" elevation={Elevation.ONE}>
                    <div className="exploreHeader">
                        <h2>Top Options by Open Interest</h2>
                    </div>
                    <div className="exploreCarousel">
                        <TopOptionCard/>
                        <TopOptionCard/>
                        <TopOptionCard/>
                    </div>
                </Card>
            </div> 
        </>

    );
}

export default Explore;