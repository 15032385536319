import './CommonDataPoint.scss';


function CommonDataPoint({data_label, data_value}){
    return (
        <div className="dataPoint">
            <p className="dataType">{data_label}</p>
            <p className="dataValue">{data_value}</p>
        </div>
    )
}

export default CommonDataPoint;