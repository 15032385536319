import './Details.scss';
import OptionChain from '../features/Dashboard/OptionChain';
import ChainFilter from '../features/Dashboard/ChainFilter';
import StockPrice from '../features/Common/StockPrice';
import StockDetails from '../features/Dashboard/StockDetails'
import Subheader from '../features/Layout/Subheader';
import { useParams} from 'react-router';
import OptionChart from '../features/Dashboard/OptionChart';
import { Card, Elevation } from "@blueprintjs/core";

function Details(){
    let { ticker } = useParams();

    return (
        <>
            <Subheader></Subheader>
            <div className="detailsContainer">
                <div className="detailsContent">
                    <div className="detailsHeader detailsRow">
                        <div className="mainDetailCol">
                            <StockPrice curr_price="420.60" perc_chng="+1.09"/>
                            <h3>Tesla Inc. ({ticker})</h3>
                        </div>
                        <StockDetails ticker={ticker}/>
                    </div>
                        <Card className="bp3-dark detailsFilter detailsRow" elevation={Elevation.TWO}>
                            <ChainFilter ticker={ticker}/>
                        </Card>

                        <div className="detailsChart detailsRow">
                            <OptionChart ticker={ticker}/>
                        </div>
                        <div className="detailsData detailsRow">
                            <OptionChain ticker={ticker}/>
                        </div>
                </div>
            </div>
        </>

    );
}

export default Details;