import './ChainFilter.scss';
import OptionDateFilter from './OptionDateFilter';
import OptionSPFilter from './OptionSPFilter';
import { useState, useEffect, useCallback, useContext } from 'react';
import apiService from '../../../services/apiService'
import AppContext from '../AppContext';



function ChainFilter({ticker}){
    const [dateData, setDatesData] = useState([]);
    const [spData, setSPData] = useState([]);
    const [loading, setLoading] = useState(true);
    const {date, sp, setDate, setSP} = useContext(AppContext);

    // Dates data
    useEffect(()=>{
        async function fetchData(){
            setLoading(true)
            const data = await apiService.getListOfExpiryDates(ticker)
            setDatesData(data)
            setDate(data[0])
            setLoading(false)
        }
        fetchData();
    },[ticker, setDate])

    // SP Data
    useEffect(()=>{
        async function fetchData(){
            const data = await apiService.getListOfStrikePrices(ticker, date)
            setSPData(data)
            setSP(data[0])
        }
        fetchData();
    },[ticker, date, setSP])

    
    // Callback for the date filter
    const dateChangeCallback = useCallback((data) =>{
        data.target.children.length > 0 ? 
        setDate(data.target.children[0].innerHTML) : 
        setDate(data.target.innerHTML)
    }, [setDate])

    // Callback for the strike price filter
    const spChangeCallback = useCallback((data) =>{
        data.target.children.length > 0 ? 
        setSP(data.target.children[0].innerHTML) : 
        setSP(data.target.innerHTML)
    }, [setSP])


    return (
        <>
            <OptionDateFilter loading={loading} data={dateData} selectedDate={date} onChangeHandler={dateChangeCallback}/>
            <OptionSPFilter loading={loading} data={spData} selectedSP={sp} onChangeHandler={spChangeCallback}/>
        </>
    )
}

export default ChainFilter;
