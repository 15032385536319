import './ExploreWizard.scss';
import React from 'react';
import Subheader from '../../features/Layout/Subheader';
import img_path from '../../../assets/img/explore-image.png';
import { Switch, Label, Button, RadioGroup, Radio, InputGroup } from '@blueprintjs/core';
import {useState} from 'react';

function ExploreWizard(){
    const [showStock, setShowStock] = useState(false)
    const [stock, setStock] = useState("")
    const [tf, setTF] = useState("tf1")

    let textInput = React.createRef();
    const handleStockChange = () => {
        setStock(textInput.current.value.toUpperCase())
    }

    const toggleSwitch = () => {
        setShowStock(!showStock)
        setStock("")
    }

    const submitAnswers = () => {
        console.log(stock)
        console.log(tf)
    }

    return (
        <>
            <Subheader></Subheader>
            <div className="wizContainer">
                <div className="wizQuestions">
                    <div className="wizHeader">
                        <h1>Welcome to the Explorer</h1>
                        <p>To get started, please answer the questions below and we'll help you explore and find suitable trading opportunities.</p>
                    </div>
                    <div className="question">
                        <Label>Do you have a stock in mind that you'd like to trade?</Label>
                        <div className="stockSearchSwitch">
                            <Switch innerLabelChecked="Yes" innerLabel="No" checked={showStock} onChange={toggleSwitch} large></Switch>
                            { showStock &&
                                // Show stock drop down
                                <InputGroup inputRef={textInput}
                                            placeholder="TSLA" 
                                            style={{ textTransform: 'uppercase'}} 
                                            leftIcon="search"
                                            onChange={handleStockChange}
                                            round />
                            }
                        </div>
                    </div>
                    <div className="question">
                        <Label>What timeframe do you want to trade in?</Label>
                        <RadioGroup selectedValue={tf} onChange={(e)=>{setTF(e.target.value)}} inline>
                            <Radio label="< 1 week" value="tf1" />
                            <Radio label="< 1 month" value="tf2" />
                            <Radio label="1-3 months" value="tf3" />
                            <Radio label="3+ months" value="tf4" />
                        </RadioGroup>
                    </div>
                    <div className="wizFooter">
                        <Button text="Feature Under Construction" onClick={submitAnswers} disabled></Button>
                    </div>
                </div>
                <div className="wizImage">
                    <img src={img_path} alt="Woman pointing to questions"></img>
                </div>
            </div>
        </>

    );
}

export default ExploreWizard;