import './Subheader.scss'
import { Button } from '@blueprintjs/core';
import { useContext} from 'react';
import {useHistory} from 'react-router-dom';
import  AppContext  from '../AppContext';


function Subheader(props){
    const history = useHistory();
    const {setActivePage, sidebarItems} = useContext(AppContext);
    const handleOnClick = () => {
        let homepage = sidebarItems[0].pagePath
        setActivePage(homepage)
        history.push(homepage);
    }


    return(
        <div className="subheader">
                <Button text="Go back" icon="arrow-left" onClick={handleOnClick} minimal></Button>
            </div>
    );
}

export default Subheader;