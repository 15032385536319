import './NewComp.scss';

function NewComp(){

        return (
            <>
                <div className="newCompContainer">
                    <div className="newComp">
                        </div>
                </div>
            </>        
        )
    }

export default NewComp;