import './Sidebar.scss';
import { useContext, useEffect } from 'react';
import logo from '../../../assets/img/logo.png';
import { Button, Icon, Drawer, Position } from '@blueprintjs/core';
import classNames from 'classnames';
import  AppContext  from '../AppContext';
import {useHistory} from 'react-router-dom';


function Sidebar(){
    const {activePage, setActivePage, sidebarItems, isOpen, toggleSidebar} = useContext(AppContext);
    const history = useHistory();

    const compPlayground = () => {
        setActivePage("/new")
    }

    const sidebarClasses = classNames({
        'appSidebar' : true,
        'show' : isOpen
    })

    const handleSidebarClick = pagePath => e =>{
        setActivePage(pagePath)
    }

    useEffect(()=>{
        async function changePage(){
                history.push(activePage);
        }
        changePage()
    },[activePage, history])

    const sidebarHTML = sidebarItems.map((val, idx) => {
        var className;
        if (val.pagePath === activePage){
            className="sidebarRow active"
        } else{
            className="sidebarRow"
        }
        return (
            <div className={className}  onClick={handleSidebarClick(val.pagePath)} key ={idx}>
                        <Icon className={val.iconClasses} icon={val.icon} iconSize={"16px"} />
                        <p>{val.name}</p>
                    </div>
        )
    })

    return (
        <Drawer className={sidebarClasses}
                isOpen={isOpen} 
                position={Position.LEFT}
                size="300px"
                onClose={toggleSidebar}
                canEscapeKeyClose
                canOutsideClickClose>
                    <div className="sidebarHeader">
                        <img src={logo} alt="GITM Logo"/>
                        <div className="toggleDiv">
                            <Button minimal onClick={toggleSidebar}>
                                <Icon icon="cross" iconSize={"20px"} />
                            </Button>
                        </div>
                    </div>
                    <div className="sidebarBody">
                        {sidebarHTML}
                    </div>
                    <div className="sidebarFooter">
                        <p>Note: <span className="comingSoon">Red</span> icons denote features which are under construction.</p>
                        <Button className="compPlaygroundBtn" minimal onClick={compPlayground}>
                                <Icon icon="insert" iconSize={"10px"} />
                            </Button>
                    </div>
        </Drawer>
    );
}

export default Sidebar;