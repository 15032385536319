import React from 'react';
import './Home.scss';
import { InputGroup, Button } from '@blueprintjs/core';
import {useHistory} from 'react-router-dom';

function Home(){
    const history = useHistory();

    let textInput = React.createRef();
    const handleOnClick = () => {
        const ticker = textInput.current.value.toUpperCase()
        history.push('/details/' + ticker);
    }

    const handleOnEnter = (event) => {
        if (event.key === 'Enter') {
            const ticker = textInput.current.value.toUpperCase()
            history.push('/details/' + ticker);
        }
    }

    return (
        
        <div className="homeContainer">
            <div className="homeContent">
                <h1>Search for a Stock.</h1>      
                <InputGroup inputRef={textInput}
                            rightElement={(<Button minimal icon="search" onClick={handleOnClick}></Button>)}
                            placeholder="TSLA" 
                            style={{ textTransform: 'uppercase'}}
                            onKeyDown={handleOnEnter}
                            fill large round>
                </InputGroup>
            </div>
        </div>
    );
}

export default Home;