import { useState} from 'react';
import React from 'react';

const AppContext = React.createContext()

export const ContextProvider = ({children}) =>{
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
    const [sp, setSP] = useState("");
    const [activePage, setActivePage] = useState("/");
    const [isOpen, setIsOpen] = useState(false);


    const toggleSidebar = () => {
        if (isOpen){
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }

    const sidebarItems = [
        {
            "icon": "search",
            "name": "Search",
            "iconClasses": "",
            "pagePath": "/"
        },
        {
            "icon": "timeline-area-chart",
            "name": "Explore",
            "iconClasses": "",
            "pagePath": "explore"
        },
        {
            "icon": "hand",
            "name": "FAQs",
            "iconClasses": "",
            "pagePath": "faqs"
        },
        {
            "icon": "build",
            "name": "Customized Insights",
            "iconClasses": "comingSoon",
            "pagePath": "exploreWizard"
        },
    ]

    return(
        <AppContext.Provider value={{date, setDate, sp, setSP, activePage, setActivePage, sidebarItems, isOpen, toggleSidebar}}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;