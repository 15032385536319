/*
    Class to make all API calls
    React components will call functions in this class. 
*/
class apiService{
    constructor(){
        // TODO 
        //Auth0 token stuff 
        console.log("REST API service initialized")
        this.API_URL = "https://api.getinthemoney.com"
    }

    // API call to get the call option chain of a ticker symbol
    async getCallOptions(ticker){
        let endpoint = "/call_options/"+ ticker
        let res = await fetch(this.API_URL + endpoint)
        const data = await res.json();
        return data
    }


    // API call to get all the expiry dates associated with
    // the call options chain of a ticker
    async getListOfExpiryDates(ticker){
        let endpoint = "/call_options_expiry/"+ ticker
        let res = await fetch(this.API_URL + endpoint)
        const data = await res.json();
        return data
    }

    // API call to get all the strike dates associated with
    // the call options chain of a ticker
    async getListOfStrikePrices(ticker, date){
        let endpoint = "/call_options_sp/" + ticker + "/date?date=" + date
        let res = await fetch(this.API_URL + endpoint)
        const data = await res.json();
        return data
    }

    // API call to get the history of an option contract
    async getCallOptionHistoryFor(ticker, date, sp){
        let endpoint = "/call_option_history/" + ticker + "?date=" + date + "&price=" + sp
        let res = await fetch(this.API_URL + endpoint)
        const data = await res.json();
        return data
    }

    // API call to get the top stocks based on price movements
    async getTopStocksByMovement(){
        //let endpoint = "/top_stocks_by_movement"
        //let res = await fetch(this.API_URL + endpoint)
        //const data = await res.json();
        let data = [
            {"stock_name" : "AAL", "volatility_score": 5.5},
            {"stock_name" : "AAPL", "volatility_score": 3.4},
            {"stock_name" : "GE", "volatility_score": 2.3},
        ]
        return data
    }

    // API call to get the top stocks based on price movements
    async getTopOptionsByMovement(){
        //let endpoint = "/top_stocks_by_movement"
        //let res = await fetch(this.API_URL + endpoint)
        //const data = await res.json();
        let data = [
            {"stock_name" : "AAL", "strike_price": 11, "expiry_date": "05/16/2021", "perc_chng":"+42.4"},
            {"stock_name" : "AAPL", "strike_price": 150, "expiry_date": "03/12/2021", "perc_chng":"+29.4"},
            {"stock_name" : "GE", "strike_price": 13.50, "expiry_date": "08/01/2021", "perc_chng":"-7.4"},
            {"stock_name" : "AAL", "strike_price": 11, "expiry_date": "05/16/2021", "perc_chng":"+42.4"},
            {"stock_name" : "AAPL", "strike_price": 150, "expiry_date": "03/12/2021", "perc_chng":"+29.4"},
            {"stock_name" : "AAPL", "strike_price": 150, "expiry_date": "03/12/2021", "perc_chng":"+29.4"},
            {"stock_name" : "GE", "strike_price": 13.50, "expiry_date": "08/01/2021", "perc_chng":"-7.4"},
            {"stock_name" : "GE", "strike_price": 13.50, "expiry_date": "08/01/2021", "perc_chng":"-7.4"},
            {"stock_name" : "AAL", "strike_price": 11, "expiry_date": "05/16/2021", "perc_chng":"+42.4"},
        ]
        return data
    }

    async getStockFundamentals(ticker){
        //let endpoint = "/stock_fundamentals/ + ticker"
        //let res = await fetch(this.API_URL + endpoint)
        //const data = await res.json();
        let data = {
            "current": 420.69,
            "open": 419.11, 
            "high": 422.01,
            "low": 419.02,
            "volume": "37.56M",
            "52_wk_high": 598.78,
            "52_wk_low": 65.45,
            "avg_volume": "47.16M",
            "mkt_cap": "555.2B",
            "pe_ratio": 1170.58,
            "eps": 0.52,
        }
        return data

    }
}
const apiServiceInstance = new apiService();
window.apiService = apiServiceInstance;
export default apiServiceInstance;