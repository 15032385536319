import './StockPrice.scss';


function StockPrice({curr_price, perc_chng, size="md"}){
    let priceClasses;
    switch (size){
        case "sm":
            priceClasses = "stockPriceLine stockPriceLine-sm"
            break
        case "md":
            priceClasses = "stockPriceLine stockPriceLine-md"
            break
        case "xl":
            priceClasses = "stockPriceLine stockPriceLine-xl"
            break
        default:
            priceClasses = "stockPriceLine stockPriceLine-md"
    }
    return (
        <p className={priceClasses}>$ <span className="currPrice">{curr_price}</span> ({perc_chng}%)</p> 
    )
}

export default StockPrice;