import './Account.scss';
import { Card, Icon, Button } from "@blueprintjs/core";
import {useState} from 'react';
import Subheader from '../features/Layout/Subheader';
import { useAuth0 } from "@auth0/auth0-react";

function Account(){
        const [panel, setPanel] = useState("profile")

        return (
        <>
            <Subheader></Subheader>
            <div className="accountContainer">
                <div className="accountContent">
                    <div className="accountLeft">
                        <Card onClick={() => setPanel("profile")}>
                            <Icon icon="user" iconSize={"24px"} />
                        </Card>
                        <Card onClick={() => setPanel("billing")}>
                            <Icon icon="credit-card" iconSize={"24px"} />
                        </Card>
                    </div>
                    <div className="accountRight">
                        {panel === "profile"
                        ? <ProfilePanel/>
                        : <BillingPanel/> }
                    </div>
                </div>
            </div>
        </>
        );
}



function ProfilePanel(){
    const { user} = useAuth0();
    return(
        <>
        <h1>Profile</h1> 
        <div className="detailRow">
            <div className="detailCell">Username</div>
            <div className="detailCell boldCell">{user.nickname}</div>
        </div>
        <div className="detailRow dark">
            <div className="detailCell">Email</div>
            <div className="detailCell boldCell">{user.email}</div>
        </div>
        <div className="detailRow">
            <div className="detailCell">Password</div>
            <div className="detailCell boldCell">*************</div>
        </div>
        <div className="detailRow detailFooter">
                <div className="detailCell"><Button className="bp3-small" intent="Warning">Change Password</Button></div>
                <div className="detailCell"><Button className="bp3-small" intent="Danger">Delete Account</Button></div>
        </div>
        </>
    );
}

function BillingPanel(){
    return(
        <>
        <h1>Billing</h1> 
        <div className="detailRow">
            <div className="detailCell">Cardholder Name</div>
            <div className="detailCell boldCell">John Smith</div>
        </div>
        <div className="detailRow dark">
            <div className="detailCell">Card Number</div>
            <div className="detailCell boldCell">**** **** **** 1234</div>
        </div>
        <div className="detailRow">
            <div className="detailCell">Subscription Plan</div>
            <div className="detailCell boldCell">Premium</div>
        </div>
        </>
    );
}

export default Account;