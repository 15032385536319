import './Faqs.scss';
import Subheader from '../features/Layout/Subheader';

function Faqs(){
        return (
        <>
            <Subheader></Subheader>
            <div className="faqsContainer">
                <div className="faqsHeader">
                    <h1>Frequently Asked Questions</h1>
                </div>
                <div className="faqsBody">
                    <div className="qaBody">
                        <p className="question">
                            Q: Who is GITM designed for?
                        </p>
                        <p className="answer">
                            A: GITM is designed for a wide spectrum of traders. Semi-professional traders, all the way from hobbyists to full-time traders, will find the resources and community on GITM useful. 
                        </p>
                    </div>
                    <div className="qaBody">
                        <p className="question">
                            Q: What separates GITM from other resources like Yahoo Finance/Barchart?
                        </p>
                        <p className="answer">
                            A: GITM was designed primarily as a resource for options trading. A lot of the other websites focus on stock data and fundamentals, but if you're trading options you should be analyzing both stocks and options. Our platform brings together high quality real-time and historic data, combined with alternative information such as financials, SEC filings and breaking news. <br/><br/>
                            Additionally, GITM is a private, invite-only platform which means that our users will have an edge over other traders, making profitable trades more likely. 
                        </p>
                    </div>
                    <div className="qaBody">
                        <p className="question">
                            Q: What trading research use-cases does GITM support?
                        </p>
                        <p className="answer">
                            A: We've designed GITM as both a live-trading and daily research tool. Our data-rich platform offers features like-
                        </p>
                            <ul className="answer">
                                <li>Real-time and historic options data</li>
                                <li>Real-time and historic stock data</li>
                                <li>Daily high movement options alerts</li>
                                <li>Financial Reports</li>
                            </ul>
                        <p>
                            The platform will continue to grow and expand upon features that the users would like to help with their trading and more about the future of the platform can be seen below. 
                        </p>
                    </div>
                    <div className="qaBody">
                        <p className="question">
                            Q: What are future features/updates that the platform will be offering users?
                        </p>
                        <p className="answer">
                            A: We want GITM to be the hands-down best resource for options traders. With that in mind, we'll continue growing the platform with new features, data and insights. Some of the future features include-
                        </p>
                            <ul className="answer">
                                <li>Expanded insights using per-user personalization</li>
                                <li>Digitalized SEC forms filed by the companies/insiders</li>
                                <li>Watchlists, including custom price alarms/triggers for both options/stocks</li>
                                <li>Lives news feeds + alerts</li>
                                <li>Educational and social channels with the best in the industry</li>
                            </ul>
                        <p>
                            And much more. We're a platform built by traders for traders and will keep a very open line of communication with our users. If there's data out there that will help improve your trading, we'll do our best to get it for you; all in the same place. 
                        </p>
                    </div>
                </div>
            </div>
        </>
        );
}

export default Faqs;