import './Header.scss';
import { useContext } from 'react';
import { Button, Navbar, Alignment , Popover, Position , Icon} from '@blueprintjs/core';
import AccountMenu from "./AccountMenu";
import { useRouteMatch } from "react-router-dom";
import  AppContext  from '../AppContext';


function Header(props){
    const {toggleSidebar} = useContext(AppContext);
    // nav links for reusing navbar
    const navLinks = props.links.map((link) => 
    <Button className="bp3-minimal" text={link} />);
    const showAccount = useRouteMatch("/login");
    
    return(
        <Navbar className="navbarContainer">
        <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>
                <Button minimal onClick={toggleSidebar}>
                    <Icon icon="menu" iconSize={"20px"} />
                </Button>
            </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
            {navLinks}
            {!showAccount && 
                <Popover content={<AccountMenu />} position={Position.BOTTOM_RIGHT}>
                    <Button minimal>
                        <Icon icon="user" iconSize={"24px"} />
                    </Button>
                </Popover>
                }
        </Navbar.Group>
      </Navbar>

    );
}

export default Header;