import { ButtonGroup, Button, Intent } from '@blueprintjs/core';


function OptionDateFilter({loading, data, selectedDate, onChangeHandler}){

    const DateBtn = (({data})=>(
        <>
            {data.map(date =>(
                <Button key={date}
                        intent={date === selectedDate ? Intent.PRIMARY : Intent.NONE}
                        text={date}
                        onClick={onChangeHandler}></Button>
                        )
                    )
            }
        </>
    ))

    return ( loading ? 
                <div className="col">
                <label className="btnLabel">Expiry Date</label>
                    <></>
                </div>
            :
                <div className="col">
                    <label className="btnLabel">Expiry Date</label>
                    <ButtonGroup className="btnGroupDiv" minimal>
                        <DateBtn data={data}></DateBtn>
                    </ButtonGroup>
                </div>
    )

}

export default OptionDateFilter;