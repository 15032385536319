import './OptionPrice.scss';


function OptionPrice({strike_price, option_type, size="md"}){
    let priceClasses;
    switch (size){
        case "sm":
            priceClasses = "optionPriceLine optionPriceLine-sm"
            break
        case "md":
            priceClasses = "optionPriceLine optionPriceLine-md"
            break
        case "xl":
            priceClasses = "optionPriceLine optionPriceLine-xl"
            break
        default:
            priceClasses = "optionPriceLine optionPriceLine-md"
    }
    return (
        <p className={priceClasses}>$<span className="currPrice">{strike_price}</span> {option_type}</p> 
    )
}

export default OptionPrice;