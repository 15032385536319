import './TopOptionCard.scss';
import OptionPrice from '../Common/OptionPrice';
import CommonDataPoint from '../Common/CommonDataPoint';
import { Button, Card, Elevation } from "@blueprintjs/core";


function TopOptionCard(){

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const data = {
        strike_price: 12, 
        contract_type: "Call",
        stock_price: 11.80,
        breakeven_price: 12.80,
        expiry_dt: "05/16/2021",
        open_int: numberWithCommas(2123000)
    }
    return (
        <Card className="optionCard" interactive elevation={Elevation.ONE}>
            <div className="optionCardHeader">
                <p className="stockTicker">GE</p>
                <p className="stockName">General Electric</p>
                <OptionPrice strike_price={data.strike_price} option_type={data.contract_type} size="md"/>
                </div>
            <div className="optionCardBody">
                <div className="left">
                    <CommonDataPoint data_label="Current Price" data_value={data.stock_price}/>
                    <CommonDataPoint data_label="Breakeven Price" data_value={data.breakeven_price}/>
                </div>
                <div className="right">
                    <CommonDataPoint data_label="Expiry Date" data_value={data.expiry_dt}/>
                    <CommonDataPoint data_label="Open Interest" data_value={data.open_int}/>
                </div>
            </div>
            <div className="optionCardFooter">
                <Button rightIcon="send-message" intent="success" minimal>More Details</Button> 
            </div>

        </Card>
    )
}

export default TopOptionCard;