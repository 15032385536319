import './LoggedInScreen.scss'
import Header from './features/Layout/Header';
import Sidebar from './features/Layout/Sidebar';
import TopMessageBar from './features/Layout/TopMessageBar';
import Account from './pages/Account';
import Details from './pages/Details';
import Explore from './pages/Explore';
import Faqs from './pages/Faqs';
import NewComp from './pages/NewComp';
import ExploreWizard from './features/Explore/ExploreWizard';
import Home from './pages/Home';
import { ContextProvider } from './features/AppContext'

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
  
  
  function LoggedInScreen(){
      return(
        <Router>
            <ContextProvider>
                <TopMessageBar></TopMessageBar>
                <div className="loggedInScreen">
                    <Sidebar/>
                    <div className="appBody">
                        <Header links={[]}></Header>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/account">
                            <Account />
                        </Route>
                        <Route path="/details/:ticker">
                            <Details />
                        </Route>
                        <Route path="/explore">
                            <Explore />
                        </Route>
                        <Route path="/exploreWizard">
                            <ExploreWizard />
                        </Route>
                        <Route path="/faqs">
                            <Faqs />
                        </Route>
                        <Route path="/new">
                            <NewComp />
                        </Route>
                    </Switch>
                    </div>
                </div>
            </ContextProvider>
        </Router>

      );


}

export default LoggedInScreen;